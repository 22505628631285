@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600&display=swap');

/* Font Family for subheading in Blogs */
@import url('https://fonts.googleapis.com/css2?family=Gochi+Hand&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gochi+Hand&family=Sriracha&display=swap');






*{
    font-family: 'Poppins', sans-serif;
}


/* ------------------------ CSS For Blogs ------------------- */


@media (min-width: 768px) {
    /* Main Image */
    img.blog_image{
      height: 600px;
    }
  
    .block_content p {
      font-size: 20px;
    }
}
  
  .block_content p {
    line-height: 1.6;
  }
  
  .block_content h2 {
    font-weight: 400; /* Default */
    font-size: 36px;
    margin: 100px 0 20px;
    font-family: 'Gochi Hand', cursive;
    font-family: 'Sriracha', cursive;
  }
  
  .block_content h1 {
    font-weight: 700;
    font-size: 40px;
    margin: 10px 0 20px;
    font-family: 'Gochi Hand', cursive;
    font-family: 'Sriracha', cursive;
  }
  
  .block_content a{
    color: #731FFC;
    font-weight: 500;
  }
  .block_content a:hover{
    color: #b688ff;
  }
  
  .block_content img {
    margin: 2rem 0;
    border-radius: 1.2rem;
    width: 70%;
  }
  
  .block_content blockquote {
    padding: 10px 20px;
    margin: 20px;
    border-left: 4px solid #171717;
    color: #3a3a3a;
  }
  
  /* Making Responsive */
  @media (max-width: 700px){
    .block_content img {
      width: 100%;
      border-radius: 10px;
    }
  
    /* Quote in sanity */
    .block_content blockquote{
      margin: 0;
      padding: 10px;
    }
  }
  
  
  /* -----------x------------ CSS For Blogs ----------x-------- */